import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiEye, FiTrash2 } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import {
  ActionButton,
  Breadcrumb,
  Button,
  CoinsIcon,
  StepProgress,
} from '../../../../components';
import Count from '../../../../components/count';
import InputPrice from '../../../../components/input-price';
import Loading from '../../../../components/loading';
import { EditProfitabilityModal } from '../../../../components/modal/edit-profitability';
import { KitDescriptionModal } from '../../../../components/modal/kit-description';
import RadioGroup from '../../../../components/radio-group';
import Textarea from '../../../../components/textarea';
import { useAuth } from '../../../../contexts/auth';
import { useBudgets } from '../../../../contexts/budgets';
import { BudgetItem } from '../../../../contexts/budgets/types';
import useMediaQuery from '../../../../hooks/mediaQuery';
import productsApi from '../../../../services/products';
import { toMoneyFormat } from '../../../../utils/toMoneyFormat';
import { normalize } from '../../../../utils/normalize';
import { ButtonGroup, PageHeader } from '../../../styles';
import {
  Actions,
  Container,
  Content,
  ServicesContainer,
  ServicesSummaryWrapper,
  TableContainer,
  TableFooter,
  TableHeader,
  ValuesData,
} from './styles';

type Form = {
  hideInstalationKit: boolean;
  bonusInsurance: boolean;
  monthlyIncome: string;
  observations: string;
};

const steps = [
  { key: 1, title: 'Selecionar produto', active: false, complete: true },
  { key: 2, title: 'Cadastro do cliente', active: false, complete: true },
  { key: 3, title: 'Selecionar Kits', active: false, complete: true },
  { key: 4, title: 'Serviços', active: true, complete: false },
  { key: 5, title: 'Proposta', active: false, complete: false },
];

type Modals = {
  description: string | null;
  profitability: boolean;
};

const BudgetsServicesPage: React.FC = () => {
  const { selectedFranchise: franchiseId } = useAuth();

  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const { pathname } = history.location;

  const hasEditPath = pathname.includes('editar');

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Selecionar produto',
      link: '/orcamentos/selecionar-produto',
      active: false,
    },
    {
      id: 3,
      title: 'Cadastro do cliente',
      link: hasEditPath
        ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/editar`
        : `/orcamentos/selecionar-produto/cadastro-cliente`,
      active: false,
    },
    {
      id: 4,
      title: 'Seleção de kits',
      link: hasEditPath
        ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/editar`
        : `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/`,
      active: false,
    },
    {
      id: 5,
      title: 'Serviços',
      link: hasEditPath
        ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/servicos/editar`
        : `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/servicos`,
      active: true,
    },
  ];

  const isTablet = useMediaQuery('(max-width: 825px)');

  const {
    budget,
    services,
    products,
    handleServices,
    handleSelectKit,
    handleIncrementQuantity,
    handleDecrementQuantity,
    handleProducts,
    getProfitability,
    getBudget,
  } = useBudgets();

  const [modals, setModals] = useState<Modals>({
    description: null,
    profitability: false,
  });

  const [loading, setLoading] = useState(false);

  const isMountedRef = useRef(false);

  const schema = yup.object().shape(
    {
      hideInstalationKit: yup.boolean(),
      bonusInsurance: yup.boolean(),
      monthlyIncome: hasEditPath ?
        yup.string().optional() :
        yup.string().required('Campo obrigatório'),
      observations: yup.string().optional(),
    },
    [['price', 'quantityPerYear']]
  );

  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
    reset,
  } = useForm<Form>({
    shouldFocusError: false,
    resolver: yupResolver(schema),
    defaultValues: {
      hideInstalationKit: false,
      bonusInsurance: false,
    },
  });

  const hasErrors = !!Object.values(errors).length;

  const updateBudgetStatus = async (budgetId: string) => {
    await productsApi.patch(`/budgets/${budgetId}`, {
      status: 'Completo',
    });
  };

  const createProposal = async (data: Form) => {
    const { hideInstalationKit, observations } = data;

    setLoading(true);

    try {
      await productsApi.patch(`/budgets/${id}/service-info`, {
        hideInstalationKit: !hideInstalationKit,
        observations: observations || undefined,
      });

      if (!hasEditPath) {
        await productsApi.post(`/banks/btg/solar-credit-analysis`, {
          budgetId: id,
          monthlyBilling: normalize(data.monthlyIncome)
        });
      }

      await updateBudgetStatus(id);

      history.replace(
        `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/servicos/proposta/${
          hasEditPath ? 'editar' : ''
        }`
      );
    } catch (error) {
      toast.error('Não foi possível gerar sua proposta');
    } finally {
      setLoading(false);
    }
  };

  const openProfitabilityModal = async () => {
    await getProfitability();

    setModals((state) => {
      return {
        ...state,
        profitability: true,
      };
    });
  };

  const closeProfitabilityModal = () => {
    setModals((state) => {
      return {
        ...state,
        profitability: false,
      };
    });
  };

  const updateServices = async () => {
    try {
      const items = products.map((product) => ({
        budgetItemId: product.budgetItemId,
        quantity: product.quantity,
        idealProfitability: product.idealProfitability,
      }));

      const { data } = await productsApi.put<BudgetItem[]>(
        `/budgets/budget-item-value/multiple`,
        {
          items,
          budgetId: id,
          franchiseId,
        }
      );

      handleServices(data);

      const hasServices = !!data.length;

      if (!hasServices) {
        history.push(
          `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/${
            hasEditPath ? 'editar' : ''
          }`
        );

        return;
      }
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data.message;

      if (errorMessage.includes('Ideal profitability needs to be higher')) {
        return;
      }

      toast.error(errorMessage);
    }
  };

  const deleteKit = async (budgetItem: BudgetItem) => {
    const { product } = budgetItem;

    if (!product) {
      return;
    }

    try {
      await productsApi.delete(`/budgets/${id}/${budgetItem.id}/remove-item`);

      handleSelectKit(product, product.promotional);

      const normalizedProducts = products.filter(
        (product) => product.budgetItemId !== budgetItem.id
      );

      handleProducts(normalizedProducts);
    } catch (error) {
      toast.error('Desculpe, não foi possível remover o kit');
    }
  };

  useEffect(() => {
    if (isMountedRef.current) {
      updateServices();
    } else {
      isMountedRef.current = true;
    }
  }, [products]);

  useEffect(() => {
    if (!budget) {
      getBudget(id);

      return;
    }

    reset({
      hideInstalationKit: budget?.hideInstalationKit,
      observations: budget?.observations ?? '',
    });
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>Serviços</span>
        <div>
          <StepProgress steps={steps} />
        </div>
      </PageHeader>

      <Content onSubmit={handleSubmit(createProposal)}>
        {!loading && (
          <TableContainer>
            <TableHeader>
              <h1>Kits</h1>

              <div>
                <Button
                  type="button"
                  text="Editar valores"
                  icon={<CoinsIcon />}
                  onClick={openProfitabilityModal}
                  className="edit-values"
                />
              </div>
            </TableHeader>
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Fornecedor</th>
                    <th>Categoria</th>
                    <th>Kits</th>
                    <th>Inversor</th>
                    <th>Valores</th>
                    <th>Quantidade</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {services.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <strong>{item.product?.supplier}</strong>
                        </td>
                        <td>
                          <strong>{item.product?.category}W</strong>
                        </td>
                        <td>
                          <strong>
                            {item.product?.power} KWp | {item.product?.voltage}V
                            <span>
                              {item.product?.solarPlatesNumber} módulos |{' '}
                              {item.totalGeneration}KWh
                            </span>
                          </strong>
                        </td>
                        <td>
                          <strong>{item.product?.inverterType}</strong>
                        </td>
                        <td>
                          <ValuesData>
                            <span>Kit: {item.formatted.price}</span>
                            <span>Instalação: {item.formatted.income}</span>
                            <span className="hr" />
                            <strong>
                              Total:{' '}
                              {toMoneyFormat(item.total - item.maintenance)}
                            </strong>
                          </ValuesData>
                        </td>
                        <td>
                          <Count
                            name={item.product?.id}
                            value={item.quantity}
                            onDecrement={async () => {
                              if (item.quantity > 1) {
                                handleDecrementQuantity(item.id);

                                return;
                              }
                            }}
                            onIncrement={async () => {
                              handleIncrementQuantity(item.id);
                            }}
                          />
                        </td>
                        <td>
                          <Actions>
                            <ActionButton
                              tooltip="Remover"
                              onClick={async () => {
                                await deleteKit(item);
                              }}
                            >
                              <FiTrash2 className="delete-icon" />
                            </ActionButton>
                            <ActionButton
                              tooltip="Visualizar"
                              onClick={() => {
                                setModals((state) => {
                                  return {
                                    ...state,
                                    description:
                                      item.product?.description ?? '',
                                  };
                                });
                              }}
                            >
                              <FiEye />
                            </ActionButton>
                          </Actions>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <TableFooter />
              </table>
            </div>
          </TableContainer>
        )}

        <ServicesSummaryWrapper>
          <ServicesContainer>
            <Controller
              name="hideInstalationKit"
              control={control}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <RadioGroup
                    ref={ref}
                    label="Mostrar valores separados?"
                    rowMode
                    options={[
                      { label: 'Sim', value: 'true' },
                      { label: 'Não', value: 'false' },
                    ]}
                    onChange={(event) =>
                      onChange(JSON.parse(event.target.value))
                    }
                    value={JSON.stringify(value)}
                  />
                );
              }}
            />
            <Controller
              name="bonusInsurance"
              control={control}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <RadioGroup
                    ref={ref}
                    label="Seguro bonificado?"
                    rowMode
                    options={[
                      { label: 'Sim', value: 'true' },
                      { label: 'Não', value: 'false' },
                    ]}
                    onChange={(event) =>
                      onChange(JSON.parse(event.target.value))
                    }
                    value={JSON.stringify(value)}
                  />
                );
              }}
            />
            {!hasEditPath && (
              <InputPrice
                label="Renda mensal média"
                type="text"
                placeholder="R$ 0,00"
                style={{ width: isTablet ? '100%' : '16.125rem' }}
                {...register('monthlyIncome')}
                error={errors.monthlyIncome?.message}
              />
            )}
          </ServicesContainer>

          <Textarea
            label="Observações do orçamento"
            height="8rem"
            placeholder="Digite aqui as observações do orçamento"
            {...register('observations')}
          />
        </ServicesSummaryWrapper>

        <ButtonGroup>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() => {
              history.push(
                `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/${
                  hasEditPath ? 'editar' : ''
                }`
              );
            }}
          />

          <Button type="submit" disabled={hasErrors} text="Gerar proposta" />
        </ButtonGroup>
      </Content>

      <KitDescriptionModal
        isOpen={!!modals.description}
        description={modals.description as string}
        onCancel={() => {
          setModals((state) => {
            return {
              ...state,
              description: null,
            };
          });
        }}
      />

      <EditProfitabilityModal
        isOpen={modals.profitability}
        onCancel={closeProfitabilityModal}
        onRequestClose={closeProfitabilityModal}
      />
    </Container>
  );
};

export default BudgetsServicesPage;
