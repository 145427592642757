type Dealership = {
  id: string;
  name: string;
};

type Addresses = {
  id: string;
  zipCode: string;
  street: string;
  number: number;
  neighborhood: string;
  complement: string;
  city: string;
  state: string;
  customerId: string;
  updatedAt: string;
};

export type Customer = {
  id: string;
  name: string;
  document: string;
  normalizedDocument: string;
  documentType: string;
  email: string;
  phone: string;
  addresses: Addresses[];
};

export type CustomKit = {
  id: string;
  productId: string;
  budgetCustomItemId: string;
  quantity: number;
  price: string;
  total: string;
  orientation: string;
  generationBySolarPlate: string;
  solarPlatesNumber: number;
  totalGeneration: string;
  product: Kit;
};

export type CustomItem = {
  composition: string;
  createdAt: string;
  generationBySolarPlate: string;
  id: string;
  kits: CustomKit[];
  solarPlatesNumber: number;
  supplier: string;
  totalGeneration: string;
  type: string;
  updatedAt: string;
  value: number;
  voltage: string;
};

export type BudgetItem = {
  budgetCustomItemId: string | null;
  budgetId: string;
  createdAt: string;
  customItem: CustomItem | null;
  generationBySolarPlates: number;
  id: string;
  idealProfitability: number;
  income: number;
  maintenance: number;
  orderId: string;
  price: number;
  product: Kit | null;
  quantity: number;
  status: string;
  total: number;
  totalGeneration: number;
  type: string;
  updatedAt: string;
  formatted: Formatted;
};

type Franchise = {
  id: string;
  name: string;
  state: string;
  customerSuccessResponsible: string;
};

export type Budget = {
  address: Addresses;
  addressId: string;
  code: number;
  consumption: number;
  customer: Customer;
  dealership: Dealership;
  fabricMaterial: string;
  franchiseId: string;
  franchise: Franchise;
  hideInstalationKit: boolean;
  id: string;
  items: BudgetItem[];
  kwhCost: number;
  observations: string;
  orientation: string;
  phase: string;
  status: 'Completo' | 'Incompleto' | 'Desativado';
  voltage: number;
  createdAt: string;
  updatedAt: string;
  btgSolarCreditAnalysis: BtgSolarCreditAnalysis | null
};

export enum BtgEffectiveness {
  PreApproved = "PreApproved"
}

export const BtgEffectivenessTranslate: Record<BtgEffectiveness, "Pendente" | "Aprovado" | "Recusado"> = {
  [BtgEffectiveness.PreApproved]: "Pendente"
}

export type BtgSolarCreditAnalysis = {
  id: string,
  taxId?: string,
  effectiveness?: BtgEffectiveness,
  productLimit?: number,
  limitDateExpire?: Date,
  product?: string,
  originId: string,
  description?: string,
}

export type Kit = {
  id: string;
  generation: number;
  supplier: string;
  power: string;
  category: string;
  fabricMaterial: string;
  name: string;
  supplierCode: string;
  promotional: boolean;
  description: string;
  solarPlatesNumber: number;
  voltage: string;
  phase: string;
  inverterType: string;
  inverterLv: boolean;
  weight: string;
  metreage: string;
  originalPrice: number;
  price: number;
  discount: number;
  rebate: number;
  type: string;
  deliveryTime: string;
  productType: string;
  quantity?: number;
};

export type SelectedKits = {
  kits: Kit[];
  promotionalKits: Kit[];
};

export type Formatted = {
  maintenance: string;
  income: string;
  total: string;
  price: string;
};

export type ProductService = {
  budgetItemId: string;
  idealProfitability: number;
  quantity: number;
  error?: string;
};

export type Profitability = {
  minimun: number;
  ideal: number;
  isPercentage: boolean;
  salesCommission: number;
  referralCommission: number;
  invoiceValue: number;
  royalties: number;
};

export type BudgetsContextData = {
  budget: Budget | null;
  selectedKits: SelectedKits;
  services: BudgetItem[];
  products: ProductService[];
  profitability: Profitability | null;
  handleBudget: (budget: Budget) => void;
  handleSelectKit: (kit: Kit, promotional: boolean) => void;
  handleSelectAllKits: (kits: Kit[]) => void;
  handleServices: (services: BudgetItem[]) => void;
  handleIncrementQuantity: (kitId: string) => void;
  handleDecrementQuantity: (kitId: string) => void;
  handleProducts: (products: ProductService[]) => void;
  getProfitability: () => Promise<void>;
  cleanBudgetFlow: () => void;
  getBudget: (budgetId: string) => Promise<Budget>;
  cleanSelectedKits: () => void;
  cleanServices: () => void;
};
